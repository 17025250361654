import React, { Component } from 'react'
import { connect, ConnectedProps } from 'react-redux';
import { RootState } from '../../reducers/rootReducer';
import { REACT_APP_APIM_URL, TI_MAX_UPDATED_TEMP } from '../../utilities/GlobalConstants';
import "../../assets/styles/_thermalImage.scss"
import ImageGallery from 'react-image-gallery';
import "../../../node_modules/react-image-gallery/styles/css/image-gallery.css";
import { DialogContent, DialogContentText, Paper, Button, IconButton, TextField, Tooltip as MaterialUITooltip } from '@material-ui/core';
import Loader from "react-spinners/ClipLoader";
import { KeyboardDateTimePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from '@date-io/date-fns';
import { CartesianGrid, LineChart, ResponsiveContainer, Line, XAxis, YAxis, Tooltip, Legend } from 'recharts';
import CameraSelector from '../common/CameraSelector';
import { Refresh, NavigateNext, NavigateBefore, SkipNext, FastForward, FastRewind, Info, Edit, Save } from '@material-ui/icons';
import CoilSelector from '../common/CoilSelector';
import TmtInformationBlock from "../common/TmtInformationBlock";
import { setImage, setTemp } from '../../slices/thermalImageSlice';
import { setCoils, setCurrentCoil, setSelectedDate } from '../../slices/coilSlice';
import { getCoilgroupByCamera, getCoilgroupByNonSelectedCamera, getFurnaceCameras, getFurnaceNonSelectedCameras, getTemperatureUnit } from '../../configuration';
import { TI_MIN_UPDATED_TEMP } from '../../utilities/GlobalConstants';
import store from '../..';
import { getCameraList, setCurrentCamera, ICameras, setSelectedTimestamp } from '../../slices/cameraSlice';
import { setCurrentCoilGroup } from '../../slices/coilGroupSlice'
import { TI_GALLERY_MAX_TEMP, TI_GALLERY_MIN_TEMP } from "../../utilities/GlobalConstants";
import FurnaceSelector from '../common/FurnaceSelector';
import { getToken, ParseTemperatureValue } from '../../utilities/helperFunctions';
import { AuthContextProps, withAuth } from 'react-oidc-context';

export const THERMAL_IMAGE_OVERVIEW_DASHBOARD_ID = "_THERMAL_IMAGE";

interface IThermalImageProps { }

interface OIDCAuth {
    auth?: AuthContextProps
}

const mapStateToProps = (state: RootState) => {
   return {
        token: state.authState.token,
        currCamera: state.camera.name,
        currTimeStamp: state.camera.currentTimestamp,
        currCoil: state.coil.currentCoil,
        coils: state.coil.coils,
        currImage: state.thermalImage.currSelectedImage,
        minUpdatedTemp: state.thermalImage.minUpdatedTemp,
        maxUpdatedTemp: state.thermalImage.maxUpdatedTemp,
        currentFurnaceId: state.scenarioSimulator.currentFurnaceId,
        accessibleFeatures: state.features.accessibleFeatures,
        userSelectedDate: state.coil.selectedDate,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        setImage: (name: string) => dispatch(setImage(name)),
        setCurrentCoil: (name: string) => dispatch(setCurrentCoil(name)),
        setCoils: (values: string[]) => dispatch(setCoils(values)),
        setTemp: (minTemp: number, maxTemp: number) => dispatch(setTemp({ minUpdatedTemp: minTemp, maxUpdatedTemp: maxTemp })),
        setSelectedDate: (name: any) => dispatch(setSelectedDate(name)),
        getCameraList: (data: ICameras[]) => dispatch(getCameraList(data)),
        setCurrentCamera: (name: string) => dispatch(setCurrentCamera(name)),
        setCurrentCoilGroup: (name: string) => dispatch(setCurrentCoilGroup(name)),
        setSelectedTimestamp: (timeStamp: string) => dispatch(setSelectedTimestamp(timeStamp))
    };
}

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

type IThermalImageReduxProps = PropsFromRedux & IThermalImageProps & OIDCAuth;

export type ICoil = {
    Name: string,
    Top: number,
    Bottom: number,
    TopM: number,
    BottomM: number
  };

export type InonSelectedCameraObject = {
    coilName: string,
    maxTMT: number,
    minTMT: number
 };

 export type IallCameraObject = {
    coilName: string,
    maxTMT: number,
    minTMT: number
 };

type IThermalImageState = {
    flag: boolean,
    isPlaying: boolean,
    volume: number,
    option: number,
    currSAS: string,
    img_gallery: any
    x: 0,
    y: 0,
    imgCordinatesX: number,
    imgCordinatesY: number,
    refObj: any,
    currDatetime?: Date,
    currMinTemp?: number,
    currMaxTemp?: number,
    currImgMinTempXCor?: number,
    currImgMinTempYCor?: number,
    currImgMaxTempXCor?: number,
    currImgMaxTempYCor?: number,
    latestImg?: string,
    latestImgDatetime?: Date,
    latestMinTemp?: number,
    latestMaxTemp?: number,
    latestMinTempXCor?: number,
    latestMinTempYCor?: number,
    latestMaxTempXCor?: number,
    latestMaxTempYCor?: number,
    maxProjectTemperature?: number,
    minProjectTemperature?: number,
    selectedDate: any,
    latestImageCordinates: any[],
    latestImageCoilName: string,
    selectedCOilCenterTemp: any[],
    LatestImgflag: boolean,
    recentImage: string,
    imageList: string[],
    showWarningMessage: boolean,
    latestImageLoader: boolean,
    imgGalleryLoader: boolean,
    coilGroups: string[],
    nonSelectedCameraTmt: number,
    nonSelectedCameraObject: InonSelectedCameraObject[],
    allCameraObject: IallCameraObject[]
    nonSelectedCameraCoilName: string[],
    isEditPanel: boolean,
    updatedMinTemp: number,
    updatedMaxTemp: number,
    imageName: string,
    camera: ICameras[],
    coilConfig: ICoil[],
    globalMinTemp: number,
    globalMaxTemp: number,
    navButtonEnable: boolean,
    fromMount: boolean,
    startDateForGallery : Date,
    endDateForGallery : Date,
    maxDateForGalleryStart : Date,
    maxCoilTmtPanel : number,
    minCoilTmtPanel : number
}


class ThermalImageOverview extends Component<IThermalImageReduxProps, IThermalImageState>{

    constructor(props) {
        super(props)
        this.state = {
            flag: false,
            isPlaying: false,
            volume: 0.7,
            option: Number(window.location.href.split('option=')[1]),
            currSAS: '',
            img_gallery: [],
            x: 0,
            y: 0,
            imgCordinatesX: 0,
            imgCordinatesY: 0,
            refObj: React.createRef(),
            selectedDate: new Date(),
            latestImageCordinates: [],
            latestImageCoilName: '',
            LatestImgflag: false,
            selectedCOilCenterTemp: [],
            recentImage: '',
            imageList: [],
            showWarningMessage: false,
            latestImageLoader: false,
            imgGalleryLoader: false,
            coilGroups: [],
            nonSelectedCameraTmt: 0,
            nonSelectedCameraObject:[],
            allCameraObject:[],
            nonSelectedCameraCoilName: [],
            isEditPanel: false,
            updatedMinTemp: 0,
            updatedMaxTemp: 0,
            imageName: '',
            camera: [],
            coilConfig: [],
            globalMinTemp: 0,
            globalMaxTemp: 0,
            navButtonEnable: false,
            fromMount: false,
            startDateForGallery : new Date(),
            endDateForGallery : new Date(),
            maxDateForGalleryStart : new Date(),
            maxCoilTmtPanel : 0,
            minCoilTmtPanel : 0
        }
    }

    async componentDidMount() {
        if(this.props.currCamera === "")
        {
            let furnaceCameras = getFurnaceCameras(this.props.currentFurnaceId);
            this.props.setCurrentCamera(furnaceCameras[0].display_name)
            let coilGroupData = getCoilgroupByCamera(furnaceCameras[0].display_name)
            this.props.setCurrentCoilGroup(coilGroupData[0].name)
        }
        this.callGetImages();
        //this.changeDatetime('');
        //let newd: Date = this.state.latestImgDatetime ?? new Date();
        
        let newd: Date = this.props.currTimeStamp !== "" ? new Date(Number(this.props.currTimeStamp)) : new Date();
        this.props.setSelectedTimestamp("");
        let newStartd: Date = new Date();
        let startDateForGallery = newStartd.setDate(newStartd.getDate() - 3);

        this.changeDatetime(new Date(newd));
        this.fetchCoilsConfig()
        this.setState({fromMount:true, maxDateForGalleryStart : new Date(startDateForGallery)})
        //this.props.setSelectedDate(this.state.latestImgDatetime)
        // let newd: Date = new Date();
        // let newDate = newd.setHours(newd.getHours() - 5);
        // newDate = newd.setMinutes(newd.getMinutes() + 21);
        ////this.props.setSelectedDate(new Date(newDate))
    }
    
    componentDidUpdate(prevProps: Readonly<IThermalImageReduxProps>, prevState: Readonly<IThermalImageState>, snapshot?: any): void {
        if (prevProps.currCamera !== this.props.currCamera) {
            this.callGetImages();
            this.changeDatetime(this.state.latestImgDatetime)
            this.resetHighlight();
        }
        if (prevProps.currCoil !== this.props.currCoil) {
            this.onCoilUpdate();
        }

        if (prevProps.currImage !== this.props.currImage) {
            if (this.state.updatedMaxTemp > 0 || this.state.updatedMinTemp > 0) {
                //calling updated temp for change date time
                this.updateTemp()
            }
        }

        if ((prevState.latestImgDatetime !== undefined && prevState.latestImgDatetime !== this.state.latestImgDatetime)) {
            if(!this.state.fromMount){
            this.onCoilUpdate()
            }
            //this.callGetImages() //todo revisit this for scholven, this line is written to enable video panel on clicking now button
            //this.props.setSelectedDate(this.state.latestImgDatetime)
        }

        if(prevState.startDateForGallery !== this.state.startDateForGallery) {
            this.callGetImages()
        }
    }
    
    //Below onPause is not in use 
    onPause = () => {
        const currIndex = this.state.refObj.current.state.currentIndex
        const currImageUrl = this.state.img_gallery[currIndex];
        let temp = currImageUrl.original.split("/thermal-image/")[1];
        temp = temp.split(".png")[0];
        temp = temp.split(".")[0];
        let datetime = temp.substring(temp.indexOf('-') + 1)
        let currMinTemp = (currImageUrl.original.split("Z-")[1]).split("-")[0] === "" ? (currImageUrl.original.split("Z--")[1]).split("-")[0] : (currImageUrl.original.split("Z-")[1]).split("-")[0]
        let currMaxTemp = (currImageUrl.original.split(currMinTemp + "-")[1]).split("-")[0];
        let s = Number(String(currImageUrl.original.substring(currImageUrl.original.indexOf(",") + 1, currImageUrl.original.indexOf(")"))).trim());
        let currImgMinTempXCor = s > 630 ? s - (s - 630) : s;
        let s1 = Number(String(currImageUrl.original.substring(currImageUrl.original.indexOf("(") + 1, currImageUrl.original.indexOf(","))).trim());
        let currImgMinTempYCor = s1 > 470 ? s1 - (s1 - 470) : s1;
        let s2 = Number(String(currImageUrl.original.substring(currImageUrl.original.lastIndexOf(",") + 1, currImageUrl.original.lastIndexOf(")"))).trim());
        let currImgMaxTempXCor = s2 > 630 ? s2 - (s2 - 630) : s2;
        let s3 = Number(String(currImageUrl.original.substring(currImageUrl.original.lastIndexOf("(") + 1, currImageUrl.original.lastIndexOf(","))).trim());
        let currImgMaxTempYCor = s3 > 470 ? s3 - (s3 - 470) : s3;

        this.setState({
            isPlaying: false,
            currDatetime: new Date(datetime),
            currMinTemp: Number(Number(currMinTemp).toFixed(1)),
            currMaxTemp: Number(Number(currMaxTemp).toFixed(1)),
            currImgMinTempXCor: currImgMinTempXCor,
            currImgMinTempYCor: currImgMinTempYCor,
            currImgMaxTempXCor: currImgMaxTempXCor,
            currImgMaxTempYCor: currImgMaxTempYCor
        });
    }
    onPlay = () => {
        this.setState({ isPlaying: true });
    };
    callMouseDown = (e) => {
        if(this.props.accessibleFeatures.includes("ADVANCED_THERMAL_IMAGE")){
        this.setState({
            latestImageLoader: true
        })
        const x_cor = e.clientX - 30;
        const y_cor = e.clientY - 198;
        const latestData = this.state.latestImageCordinates;
        let centerArray = []
        let leftArray = []
        let rightArray = []
        let centerTempArray = []
        let flag = false
        let selectedCoil = ''
        latestData.forEach((x) => {
            let leftEdge = (JSON.parse(x.LeftEdge))[y_cor]
            let rightEdge = (JSON.parse(x.RightEdge))[y_cor]
            let leftXVal = Number((JSON.stringify(leftEdge).split('['))[1].split(',')[0])
            let rightXVal = Number((JSON.stringify(rightEdge).split('['))[1].split(',')[0])

            if (!flag && x_cor >= leftXVal && x_cor <= rightXVal) {
                this.setState({ latestImageCoilName: x.CoilName })
                let coilNumber = this.getCoilNumber(x.CoilName)
                selectedCoil = x.CoilName
                const latestSelectedCoil = this.props.coils.filter(x => x.includes(coilNumber.toString()))[0]
                if (latestSelectedCoil !== this.state.latestImageCoilName) {
                    this.resetHighlight();
                    this.props.setCurrentCoil(latestSelectedCoil)
                    centerArray = JSON.parse(x.Center)
                    leftArray = JSON.parse(x.LeftEdge)
                    rightArray = JSON.parse(x.RightEdge)
                    centerTempArray = JSON.parse(x.CenterTemp)
                    flag = true
                }
                else {
                    flag = false
                }
            }
        })

        let centerTempForGraph: any[] = [];
        let largest = 0;
        let largestIndex = 0;
        let lowestIndex = 0;
        let lowest: number = centerTempArray[0];
        if (flag) {

            for (let i = 0; i < centerTempArray.length; i++) {
                if (centerTempArray[i] > largest) {
                    largest = centerTempArray[i];
                    largestIndex = i;
                }
                if (centerTempArray[i] < lowest) {
                    lowest = centerTempArray[i];
                    lowestIndex = i;
                }
            }
            this.setState({
                latestMaxTemp: Number(largest.toFixed(1)),
                latestMinTemp: Number(lowest.toFixed(1)),
            })

            centerTempArray.forEach((x, idx) => {
                const outletTemperatureObjKey = "OutletTemperature("+getTemperatureUnit()+")";
                return centerTempForGraph.push({ "CoilLength": idx + 1, [outletTemperatureObjKey]: parseInt(x) });
            })
            this.setState({
                selectedCOilCenterTemp: centerTempForGraph
            })

            let centerdiv = document.createElement("div");
            centerdiv.id = 'centerCoilHighlight'
            centerdiv.className = 'center-highlight-parent'
            centerArray.forEach((c, idx) => {
                if (idx === largestIndex) {
                    let maxX = c[0] > 630 ? c[0] - (c[0] - 630) : c[0];
                    let maxY = c[1] > 470 ? c[1] - (c[1] - 470) : c[1];
                    this.setState({
                        latestMaxTempXCor: maxX,
                        latestMaxTempYCor: maxY
                    })
                }
                if (idx === lowestIndex) {
                    let minX = c[0] > 630 ? c[0] - (c[0] - 630) : c[0];
                    let minY = c[1] > 470 ? c[1] - (c[1] - 470) : c[1];
                    this.setState({
                        latestMinTempXCor: minX,
                        latestMinTempYCor: minY
                    })
                }
                let span = document.createElement("span");
                let box = document.createElement("box");
                span.className = 'center-highlight'
                span.style.cssText = 'margin-left: ' + c[0] + 'px; margin-top: ' + c[1] + 'px; '
                box.style.cssText = 'margin-left: ' + (c[0] + 10) + 'px; margin-top: ' + c[1] + 'px; '
                box.className = 'center-temp-box'
                span.innerHTML = ''
                span.addEventListener('click', (event) => {
                    let ct_box = document.getElementsByClassName("center-temp-box")
                    for (let i = 0; i < ct_box.length; i++) {
                        ct_box[i].innerHTML = ''
                    }
                    box.innerHTML = (Number(centerTempArray[c[1]]).toFixed(1))
                });
                centerdiv.appendChild(box);
                centerdiv.appendChild(span);
                document.getElementById('latestImg')?.appendChild(centerdiv);
            })

            let leftdiv = document.createElement("div");
            leftdiv.id = 'leftCoilHighlight'
            leftdiv.className = 'left-highlight-parent'
            leftArray.forEach((c) => {
                let span = document.createElement("span");
                span.className = selectedCoil.includes('OUT') ? 'left-highlight' : 'left-highlight inlet'
                span.style.cssText = 'margin-left: ' + c[0] + 'px; margin-top: ' + c[1] + 'px; '
                span.innerHTML = '';
                leftdiv.appendChild(span);
                document.getElementById('latestImg')?.appendChild(leftdiv);
            })

            let rightdiv = document.createElement("div");
            rightdiv.id = 'rightCoilHighlight'
            rightdiv.className = 'right-highlight-parent'
            rightArray.forEach((c) => {
                let span = document.createElement("span");
                span.className = selectedCoil.includes('OUT') ? 'right-highlight' : 'right-highlight inlet'
                span.style.cssText = 'margin-left: ' + c[0] + 'px; margin-top: ' + c[1] + 'px; '
                span.innerHTML = ''
                rightdiv.appendChild(span);
                document.getElementById('latestImg')?.appendChild(rightdiv);
            })
            this.setState({
                latestImageLoader: false
            })
        }
        else {
            setTimeout(() => {
                this.setState({
                    latestImageLoader: false
                })
            }, 1500);
        }
    }
    }

    onClick = (e) => {
        if (this.state.isPlaying === false) {
            const rect = e.target.getBoundingClientRect();
            const X = e.clientX - Math.round(rect.left) - 59
            const Y = e.clientY - Math.round(rect.top)
            this.setState({
                imgCordinatesX: X,
                imgCordinatesY: Y
            })
        }
    }
    getCoilNumber = (name: string) => {
        return name.substring(name.lastIndexOf("-")).match(/\d+/)![0];
    }

    navPrev = () => {
        if (this.state.navButtonEnable) {
            return;
        }
        this.setState({navButtonEnable: true});
        let newd: Date = this.state.latestImgDatetime ?? new Date();
        let newDate = newd.setMinutes(newd.getMinutes() - 5);
        this.setState({
            latestImgDatetime: new Date(newDate),
            // latestMaxTempXCor: undefined,
            // latestMaxTempYCor: undefined,
            // latestMinTempXCor: undefined,
            // latestMinTempYCor: undefined
        })
        //this.props.setSelectedDate(new Date(newDate))
        this.changeDatetime(new Date(newDate))
    }

    navNext = () => {
        if (this.state.navButtonEnable) {
            return;
        }
        this.setState({navButtonEnable: true});
        let newd: Date = this.state.latestImgDatetime ?? new Date();
        let newDate = newd.setMinutes(newd.getMinutes() + 5);
        this.setState({
            latestImgDatetime: new Date(newDate),
            // latestMaxTempXCor: undefined,
            // latestMaxTempYCor: undefined,
            // latestMinTempXCor: undefined,
            // latestMinTempYCor: undefined
        })
        //this.props.setSelectedDate(new Date(newDate))
        this.changeDatetime(new Date(newDate))      
    }

    fastForward = () => {
        if (this.state.navButtonEnable) {
            return;
        }
        this.setState({navButtonEnable: true});
        let newd: Date = this.state.latestImgDatetime ?? new Date();
        let newDate = newd.setMinutes(newd.getMinutes() + 60);
        this.setState({
            latestImgDatetime: new Date(newDate),
            // latestMaxTempXCor: undefined,
            // latestMaxTempYCor: undefined,
            // latestMinTempXCor: undefined,
            // latestMinTempYCor: undefined
        })
        //this.props.setSelectedDate(new Date(newDate))
        this.changeDatetime(new Date(newDate))
    }

    fastRewind = () => {
        if (this.state.navButtonEnable) {
            return;
        }
        this.setState({navButtonEnable: true});
        let newd: Date = this.state.latestImgDatetime ?? new Date();
        let newDate = newd.setMinutes(newd.getMinutes() - 60);
        this.setState({
            latestImgDatetime: new Date(newDate),
            // latestMaxTempXCor: undefined,
            // latestMaxTempYCor: undefined,
            // latestMinTempXCor: undefined,
            // latestMinTempYCor: undefined
        })
        //this.props.setSelectedDate(new Date(newDate))
        this.changeDatetime(new Date(newDate))
    }

    refreshLatestImage = () => {
        if (this.state.navButtonEnable) {
            return;
        }
        this.setState({navButtonEnable: true});
        this.changeDatetime('')
    }


    updateTemp = () => {
        this.setState({
            LatestImgflag: true
        });
        
        let temp = this.props.currImage?.split("/thermal-image/")[1] ?? '';
        temp = temp.split(".png")[0];
        temp = temp.split(".")[0];
        temp = temp.split("-")[0];
        const accessToken = getToken(this.props.auth);
        fetch(`${REACT_APP_APIM_URL}/thermalimage/generateNewImage/${this.state.updatedMinTemp}/${this.state.updatedMaxTemp}/${temp}`
            , {
                method: "GET",
                headers: {
                    Accept: "*/*",
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + accessToken,
                    "Access-Control-Allow-Origin": "*",
                }
            }).then((res) => res.json())
            .then(async (data) => {
                this.setState({
                    isEditPanel: false
                })
                let temp1 = this.state.latestImg?.split("/thermal-image/")[0] ?? '';
                let temp2 = this.state.latestImg?.split("/thermal-image/")[1] ?? '';
                temp2 = temp2.split("?")[1];
                this.setState({
                    latestImg: temp1 + "/thermal-image/" + this.props.currCamera + "/" + data + "?" + temp2,
                    minProjectTemperature: this.state.updatedMinTemp,
                    maxProjectTemperature: this.state.updatedMaxTemp
                })
                this.props.setImage(temp1 + "/thermal-image/" + this.props.currCamera + "/" + data + "?" + temp2);
                this.props.setTemp(this.state.updatedMinTemp, this.state.updatedMaxTemp)
                this.setState({
                    LatestImgflag: false
                });
                this.onCoilUpdate();
            });

    }

    editPanel = () => {
        this.setState({
            isEditPanel: !this.state.isEditPanel
        })
    }
    pad = (num, size) => {
        if (num) {
            num = num.toString();
            while (num.length < size) num = "0" + num;
            return num;
        }
        return num;
    }

    changeDatetime = (val) => {
        
        this.setState({
            showWarningMessage: false
        });
        let newD = '';
        if (val !== '') {
            this.setStartDateTimePickerForGallery(val);
            this.setState({
                LatestImgflag: true,
                latestImgDatetime: new Date(val),
                endDateForGallery: new Date(val)
            })
            this.props.setSelectedDate(new Date(val))
            const dd = new Date(val);
            // newD = dd.getFullYear() + "-" + ('0' + (dd.getMonth() + 1)).slice(-2) + "-" + ('0' + dd.getDate()).slice(-2) + "T" + ('0' + dd.getHours()).slice(-2) + ":" + ('0' + dd.getMinutes()).slice(-2)
            newD = dd.toISOString().replace('Z', '');
            // this.props.setCurrentCoil('');            
        }
        const accessToken = getToken(this.props.auth);
        fetch(`${REACT_APP_APIM_URL}/thermalimage/getSelectedImage/${this.props.currCamera}` + (newD ? `/${newD}` : ``), {
            method: "GET",
            headers: {
                Accept: "*/*",
                "Content-Type": "application/json",
                Authorization: "Bearer " + accessToken,
                "Access-Control-Allow-Origin": "*",
            }
        }).then((res) => res.json())
            .then(async (data) => {

                const newImage = data;

                let result2: any[] = []

                if (newImage) {

                    this.setState({
                        currSAS: data.split('.png?')[1].split('&date')[0]
                    })

                    let imd_gallery_data = {
                        original: newImage.split("&date")[0],
                    }
                    result2.push(imd_gallery_data)

                    const currImageUrl = result2[0];
                    this.setState({
                        recentImage: currImageUrl
                    })

                    let currMinTemp
                    if(currImageUrl.original.includes("Z-") || currImageUrl.original.includes("Z--"))
                    {
                        currMinTemp = (currImageUrl.original.split("Z-")[1]).split("-")[0] === "" ? (currImageUrl.original.split("Z--")[1]).split("-")[0] : (currImageUrl.original.split("Z-")[1]).split("-")[0]
                    }
                    else
                    {
                        let currMinTempArr = currImageUrl.original.split(" ")
                        let currMinTempArr1 = currMinTempArr[1].split(":")
                        let currMinTempArr2 = currMinTempArr1[2].split("-")
                        currMinTemp = currMinTempArr2[1]
                    }
                    let currMaxTemp = (currImageUrl.original.split(currMinTemp + "-")[1]).split("-")[0];

                    let latestImg = result2[0]["original"];
                    this.props.setImage(latestImg);
                    let imgName = (latestImg.split("thermal-image")[1]).split("-")[0].split("/")[2]
                    fetch(`${REACT_APP_APIM_URL}/thermalimage/getImageCordinates/${imgName}/${this.props.currCamera}`, {
                        method: "GET",
                        headers: {
                            Accept: "*/*",
                            "Content-Type": "application/json",
                            Authorization: "Bearer " + accessToken,
                            "Access-Control-Allow-Origin": "*",
                        }
                    }).then((res) => res.json())
                        .then(async (data) => {
                            this.setState({ latestImageCordinates: data });
                            this.setState({ LatestImgflag: false })

                            if(Array.isArray(data) && data.length>0){
                                this.onCoilUpdate();
                                this.setState({fromMount:false})
                            }
                        })
                        
                    let temp1 = latestImg.split("/thermal-image/")[1];
                    temp1 = temp1.split(".png")[0];
                    temp1 = temp1.split(".")[0];
                    let datetime1 = temp1.substring(temp1.indexOf('-') + 1) + 'Z';
                    
                    await this.setState({
                        flag: true,
                        latestImg: latestImg,
                        latestImgDatetime: new Date(datetime1),
                        latestImageCoilName: '',
                        selectedCOilCenterTemp: [],
                        minProjectTemperature: parseInt(currMinTemp) === 0 ? data[0]["minTemp"] : Math.floor(currMinTemp),
                        maxProjectTemperature: parseInt(currMaxTemp) === 0 ? data[0]["maxTemp"] : Math.ceil(currMaxTemp),
                        navButtonEnable: false
                    })
                    this.props.setSelectedDate(new Date(datetime1))                
                }
                else {
                    await this.setState({
                        flag: true,
                        latestImg: "",
                        showWarningMessage: true,
                        navButtonEnable: false
                    });
                }

            },
                (error) => console.log("error", error)
            );
        const nonSelectedCameras = getFurnaceNonSelectedCameras(this.props.currentFurnaceId, this.props.currCamera)
        
        let selectedOtherCameraCoilGrouplargest = 0
        let selectedOtherCameraCoilGroupMin = 0

        for(let i = 0; i < nonSelectedCameras.length;i++)
        {
            //const alternateCamera = this.props.currCamera === "camera1" ? "camera2" : "camera1"
            fetch(`${REACT_APP_APIM_URL}/thermalimage/getSelectedImage/` + nonSelectedCameras[i].display_name + (newD ? `/${newD}` : ``), {
                method: "GET",
                headers: {
                    Accept: "*/*",
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + accessToken,
                    "Access-Control-Allow-Origin": "*",
                }
            }).then((res) => res.json())
                .then(async (data) => {

                    const newImage = data;

                    let result2: any[] = []

                    if (newImage) {

                        this.setState({
                            currSAS: data.split('.png?')[1].split('&date')[0]
                        })

                        let imd_gallery_data = {
                            original: newImage.split("&date")[0],
                        }
                        result2.push(imd_gallery_data)

                        const currImageUrl = result2[0];
                        this.setState({
                            recentImage: currImageUrl
                        })
                        let temp = currImageUrl.original.split("/thermal-image/")[1];
                        temp = temp.split(".png")[0];
                        temp = temp.split(".")[0];
                        temp = temp.split("/")[1]
                        const imgaeNameForNonSelectedCamera = temp.split("-")[0]

                        // got the name of second camera 
                        fetch(`${REACT_APP_APIM_URL}/thermalimage/getAllCamerasTemps/` + imgaeNameForNonSelectedCamera, {
                            method: "GET",
                            headers: {
                                Accept: "*/*",
                                "Content-Type": "application/json",
                                Authorization: "Bearer " + accessToken,
                                "Access-Control-Allow-Origin": "*",
                            }
                        }).then((res) => res.json())
                            .then(async (data) => {
                                let centerTempArray = []
                                
                                for (let j = 0; j < data[1].length; j++) {
                                        centerTempArray = JSON.parse(data[1][j]);
                                        for (let i = 0; i < centerTempArray.length; i++) {
                                            if (centerTempArray[i] > selectedOtherCameraCoilGrouplargest) {
                                                selectedOtherCameraCoilGrouplargest = centerTempArray[i];
                                            }
                                        }
                                }

                                for (let j = 0; j < data[1].length; j++) {
                                    centerTempArray = JSON.parse(data[1][j]);
                                    let centerTempArrayMin = Math.min.apply(Math, centerTempArray)
                                    if(i === 0)
                                    {
                                        selectedOtherCameraCoilGroupMin = centerTempArrayMin
                                    }
                                    else
                                    {
                                        if(centerTempArrayMin < selectedOtherCameraCoilGroupMin)
                                        {
                                            selectedOtherCameraCoilGroupMin = centerTempArrayMin
                                        }
                                    } 
                                }

                                let objNew = {"coilName": data[0]['CoilName'].split("CG")[1].split("_")[0], "maxTMT": selectedOtherCameraCoilGrouplargest, "minTMT": selectedOtherCameraCoilGroupMin}
                                this.setState( prevState => ({
                                    //nonSelectedCameraTmt: data[1],
                                    // nonSelectedCameraTmt: [data[1]],
                                    // nonSelectedCameraCoilName: [...this.state.nonSelectedCameraCoilName, data[0]['CoilName']],
                                    nonSelectedCameraObject : [...prevState.nonSelectedCameraObject, objNew]
                                }));

                            });
                    }
                });
        }     
    }

    resetEditPanel = () => {
        this.setState({
            LatestImgflag: true
        });

        this.setState({
            updatedMinTemp: 0,
            updatedMaxTemp: 0,
        });
        this.changeDatetime(this.state.latestImgDatetime);

    }

    fetchCoilsConfig = () => {
        const accessToken = getToken(this.props.auth);
        fetch(`${REACT_APP_APIM_URL}/thermalimage/getCoilsConfig`, {
            method: "GET",
            headers: {
                Accept: "*/*",
                "Content-Type": "application/json",
                Authorization: "Bearer " + accessToken,
                "Access-Control-Allow-Origin": "*"
            },
        })
            .then((res) => res.json())
            .then(
                (data) => {
                    this.setState({coilConfig:data})

                },
                (error) => console.log("error", error)
            )
    }

    onCoilUpdate = (latestImgCoordinates = undefined) => {
        if (this.props.currCoil) {
            const latestData = this.state.latestImageCordinates.length > 0 ? this.state.latestImageCordinates : latestImgCoordinates;
            let coilNum = this.props.currCoil?.slice(this.props.currCoil.lastIndexOf("-") + 1, this.props.currCoil.length)
            let coilNumber = parseInt(coilNum.replace(/[^0-9]+/gi, ""))

            let centerArray = []
            let leftArray = []
            let rightArray = []
            let centerTempArray = []
            this.resetHighlight()
            let newCenterTempArray: any[] = [];
            const coilConfigData = this.state.coilConfig
            
            let largest = 0;
            let lowest = 1999;
            let selectedCameraCoilGroupTMTMax = 0;
            let selectedCameraCoilGrouplargest = 0;
            let selectedCameraCoilGroupMin = 1999;
            let selectedOtherCameraCoilGroupTMTMax = 0;
            let selectedOtherCameraCoilGrouplargest = 0;

            let coilGroupsName: string[] = [];
            latestData?.forEach(x => {
                centerTempArray = JSON.parse(x.CenterTemp);


                for (let i = 0; i < centerTempArray.length; i++) {
                    if (centerTempArray[i] > selectedCameraCoilGrouplargest) {
                        selectedCameraCoilGrouplargest = centerTempArray[i];
                    }
                }

                for (let i = 0; i < centerTempArray.length; i++) {
                    if (centerTempArray[i] < selectedCameraCoilGroupMin) {
                        selectedCameraCoilGroupMin = centerTempArray[i];
                    }
                }
            });
            // if(this.state.nonSelectedCameraTmt && this.state.nonSelectedCameraTmt[0] && this.state.nonSelectedCameraTmt[0].length > 0){
            // for (let j = 0; j < this.state.nonSelectedCameraTmt[0].length; j++) {
            //     for(let k=0;k<this.state.nonSelectedCameraTmt.length;k++){
            //         centerTempArray = JSON.parse(this.state.nonSelectedCameraTmt[k][j]);
            //         for (let i = 0; i < centerTempArray.length; i++) {
            //             if (centerTempArray[i] > selectedOtherCameraCoilGrouplargest) {
            //                 selectedOtherCameraCoilGrouplargest = centerTempArray[i];
            //             }
            //         }
            //     };
            // }
            // }
            selectedOtherCameraCoilGrouplargest = this.state.nonSelectedCameraObject.length > 0 ? Math.max.apply(Math, this.state.nonSelectedCameraObject.map(function(o) { return o.maxTMT; })) : 0
            
            let selectedObj = {"coilName": latestData?.[0].CoilName.split("CG")[1].split("_")[0], "maxTMT": selectedCameraCoilGrouplargest, "minTMT": selectedCameraCoilGroupMin}

            this.setState( prevState => ({
                allCameraObject : [...prevState.nonSelectedCameraObject, selectedObj]
            }));

            this.setState({minCoilTmtPanel:Math.min.apply(Math, this.state.allCameraObject.map(function(o) { return o.minTMT; })) - 50})

            this.setState({maxCoilTmtPanel:Math.max.apply(Math, this.state.allCameraObject.map(function(o) { return o.maxTMT; })) + 50})

            coilGroupsName.push("CG" + latestData?.[0].CoilName.split("CG")[1].split("_")[0]);
            // const nonSelectedCameraCoilgroups = getCoilgroupByNonSelectedCamera(this.props.currCamera)
            // for(let i = 0; i < nonSelectedCameraCoilgroups.length;i++)
            // {
            //     coilGroupsName.push("CG-" + nonSelectedCameraCoilgroups[i].name);
            // }


            // let filterObj : any = this.state.nonSelectedCameraObject.find(o => o.maxTMT === selectedOtherCameraCoilGrouplargest);

            this.state.nonSelectedCameraObject.map((o) => {
                coilGroupsName.push("CG" + o.coilName);
            })


            // coilGroupsName.push("CG" + this.state.nonSelectedCameraCoilName[0].split("CG")[1].split("_")[0]);
            this.setState({ coilGroups: coilGroupsName }, () => {
                selectedCameraCoilGroupTMTMax = selectedCameraCoilGrouplargest;
                selectedOtherCameraCoilGroupTMTMax = selectedOtherCameraCoilGrouplargest;
                latestData?.forEach(x => {
                    if (parseInt(x.CoilName.slice(x.CoilName.lastIndexOf("-") + 1).replace(/[^0-9]+/gi, "")) === coilNumber) {
                        let largestIndex = 0;
                        let lowestIndex = 0;
                        const coil_name = x.CoilName
                        this.setState({ latestImageCoilName: x.CoilName })
                        centerArray = JSON.parse(x.Center)
                        leftArray = JSON.parse(x.LeftEdge)
                        rightArray = JSON.parse(x.RightEdge)
                        centerTempArray = JSON.parse(x.CenterTemp)

                        let centerTempForGraph: any[] = [];

                        for (let i = 0; i < centerTempArray.length; i++) {
                            if (centerTempArray[i] > largest) {
                                largest = centerTempArray[i];
                                largestIndex = i;
                            }
                            if (centerTempArray[i] < lowest) {
                                lowest = centerTempArray[i];
                                lowestIndex = i;
                            }
                        }
                        this.setState({
                            latestMaxTemp: Number(largest.toFixed(1)),
                            latestMinTemp: Number(lowest.toFixed(1))
                        })

                        const startPosition = coilConfigData.filter(y => y.Name === coil_name)[0].TopM
                        const endPosition = coilConfigData.filter(y => y.Name === coil_name)[0].BottomM
                        const factor = Number(((endPosition - startPosition) / 480).toFixed(5))
                        
                        const outletTemperatureObjKey = "OutletTemperature("+getTemperatureUnit()+")";
                        const inletATemperatureObjKey = "InletATemperature("+getTemperatureUnit()+")";
                        const inletBTemperatureObjKey = "InletBTemperature("+getTemperatureUnit()+")";

                        if (x.CoilName.includes("OUT") > 0) {
                            centerTempArray.forEach((x, idx) => {
                                return centerTempForGraph.push({ "CoilLength": parseFloat((startPosition + (factor * (idx))).toFixed(2)), [outletTemperatureObjKey]: parseInt(x) });
                            })
                        }
                        else if (x.CoilName.includes("IN") > 0 && x.CoilName.endsWith("A") > 0) {
                            centerTempArray.forEach((x, idx) => {
                                return centerTempForGraph.push({ "CoilLength": parseFloat((startPosition + (factor * (idx))).toFixed(2)), [inletATemperatureObjKey]: parseInt(x) });
                            })
                        }
                        else if (x.CoilName.includes("IN") > 0 && x.CoilName.endsWith("B") > 0) {
                            centerTempArray.forEach((x, idx) => {
                                return centerTempForGraph.push({ "CoilLength": parseFloat((startPosition + (factor * (idx))).toFixed(2)), [inletBTemperatureObjKey]: parseInt(x) });
                            })
                        }

                        centerTempForGraph.forEach((object) => {
                            const existing = newCenterTempArray.filter((item) => item["CoilLength"] === object["CoilLength"]);
                            if (existing.length) {
                                const existingIndex = newCenterTempArray.indexOf(existing[0]);
                                if (object[outletTemperatureObjKey])
                                    newCenterTempArray[existingIndex][outletTemperatureObjKey] = object[outletTemperatureObjKey];
                                if (object[inletATemperatureObjKey])
                                    newCenterTempArray[existingIndex][inletATemperatureObjKey] = object[inletATemperatureObjKey];
                                if (object[inletBTemperatureObjKey])
                                    newCenterTempArray[existingIndex][inletBTemperatureObjKey] = object[inletBTemperatureObjKey];
                                object[this.state.coilGroups[0]] = ParseTemperatureValue(selectedCameraCoilGroupTMTMax);
                                this.state.coilGroups.map((o) => {
                                    if(o !== this.state.coilGroups[0]){
                                        object[o] = ParseTemperatureValue(Math.max.apply(Math, this.state.nonSelectedCameraObject.map(function(o) { return o.maxTMT; })));
                                    }
                                })
                            } else {
                                object[this.state.coilGroups[0]] = ParseTemperatureValue(selectedCameraCoilGroupTMTMax);
                                this.state.coilGroups.map((o) => {
                                    if(o !== this.state.coilGroups[0]){
                                        object[o] = ParseTemperatureValue(Math.max.apply(Math, this.state.nonSelectedCameraObject.map(function(o) { return o.maxTMT; })));
                                    }
                                })
                                newCenterTempArray.push(object);
                            }
                        });

                        newCenterTempArray = newCenterTempArray.sort((a, b) => { return a.CoilLength - b.CoilLength });
                        
                        this.setState({
                            selectedCOilCenterTemp: newCenterTempArray
                        })

                        let centerdiv = document.createElement("div");
                        centerdiv.id = 'centerCoilHighlight'
                        centerdiv.className = 'center-highlight-parent'

                        centerArray.forEach((c, idx) => {
                            if (largestIndex > 0) {
                                if (idx === largestIndex) {
                                    let maxX = c[0] > 630 ? c[0] - (c[0] - 630) : c[0];
                                    let maxY = c[1] > 470 ? c[1] - (c[1] - 470) : c[1];
                                    this.setState({
                                        latestMaxTempXCor: maxX,
                                        latestMaxTempYCor: maxY
                                    })
                                }
                            }
                            if (lowestIndex > 0) {
                                if (idx === lowestIndex) {
                                    let minX = c[0] > 630 ? c[0] - (c[0] - 630) : c[0];
                                    let minY = c[1] > 470 ? c[1] - (c[1] - 470) : c[1];
                                    this.setState({
                                        latestMinTempXCor: minX,
                                        latestMinTempYCor: minY
                                    })
                                }
                            }

                            let span = document.createElement("span");
                            let box = document.createElement("box");
                            span.className = 'center-highlight'
                            span.style.cssText = 'margin-left: ' + c[0] + 'px; margin-top: ' + c[1] + 'px; '
                            box.style.cssText = 'margin-left: ' + (c[0] + 10) + 'px; margin-top: ' + c[1] + 'px; '
                            box.className = 'center-temp-box'
                            span.innerHTML = ''
                            span.addEventListener('click', (event) => {
                                let ct_box = document.getElementsByClassName("center-temp-box")
                                for (let i = 0; i < ct_box.length; i++) {
                                    ct_box[i].innerHTML = ''
                                }
                                box.innerHTML = (Number(centerTempArray[c[1]]).toFixed(1))
                            });
                            centerdiv.appendChild(box);
                            centerdiv.appendChild(span);
                            document.getElementById('latestImg')?.appendChild(centerdiv);
                        })

                        let leftdiv = document.createElement("div");
                        leftdiv.id = 'leftCoilHighlight'
                        leftdiv.className = 'left-highlight-parent'
                        leftArray.forEach((c) => {
                            let span = document.createElement("span");
                            span.className = x.CoilName.includes('OUT') ? 'left-highlight' : 'left-highlight inlet'
                            span.style.cssText = 'margin-left: ' + c[0] + 'px; margin-top: ' + c[1] + 'px; '
                            span.innerHTML = '';
                            leftdiv.appendChild(span);
                            document.getElementById('latestImg')?.appendChild(leftdiv);
                        })

                        let rightdiv = document.createElement("div");
                        rightdiv.id = 'rightCoilHighlight'
                        rightdiv.className = 'right-highlight-parent'
                        rightArray.forEach((c) => {
                            let span = document.createElement("span");
                            span.className = x.CoilName.includes('OUT') ? 'right-highlight' : 'right-highlight inlet'
                            span.style.cssText = 'margin-left: ' + c[0] + 'px; margin-top: ' + c[1] + 'px; '
                            span.innerHTML = ''
                            rightdiv.appendChild(span);
                            document.getElementById('latestImg')?.appendChild(rightdiv);
                        })
                    }
                })
            });
        }
    }
    getMinDate = (inputDate) => {
        const date = new Date(inputDate);
        date.setDate(date.getDate() - 90)
        return date
    }
    resetHighlight = () => {
        this.setState({
            latestMinTempXCor: undefined,
            latestMinTempYCor: undefined,
            latestMaxTempXCor: undefined,
            latestMaxTempYCor: undefined,
            selectedCOilCenterTemp: [],
        })
        let totalHighlightEle = document.querySelectorAll("[id='centerCoilHighlight']").length;
        for (var i = 0; i < totalHighlightEle; i++) {
            let eleCenter = document.getElementById("centerCoilHighlight");
            if (eleCenter != null) {
                eleCenter.remove();
            }
            let eleLeft = document.getElementById("leftCoilHighlight");
            if (eleLeft != null) {
                eleLeft.remove();
            }
            let eleRight = document.getElementById("rightCoilHighlight");
            if (eleRight != null) {
                eleRight.remove();
            }
        }
    }
    callGetImages = () => {
        
        let startDate = ''
        let startDateForGallery = new Date(this.state.startDateForGallery);
        // startDate = startDateForGallery.getFullYear() + "-" + ('0' + (startDateForGallery.getMonth() + 1)).slice(-2) + "-" + ('0' + startDateForGallery.getDate()).slice(-2) + "T" + ('0' + startDateForGallery.getHours()).slice(-2) + ":" + ('0' + startDateForGallery.getMinutes()).slice(-2)
        startDate = startDateForGallery.toISOString().replace('Z', '');
        let endDate = '';
        let endDateForGallery = new Date(this.state.endDateForGallery);
        // endDate = endDateForGallery.getFullYear() + "-" + ('0' + (endDateForGallery.getMonth() + 1)).slice(-2) + "-" + ('0' + endDateForGallery.getDate()).slice(-2) + "T" + ('0' + endDateForGallery.getHours()).slice(-2) + ":" + ('0' + endDateForGallery.getMinutes()).slice(-2)
        endDate = endDateForGallery.toISOString().replace('Z', '');
        this.setState({
            // flag: false,
            imgGalleryLoader: true
        });
        const accessToken = getToken(this.props.auth);
        fetch(`${REACT_APP_APIM_URL}/thermalimage/getImages/${this.props.currCamera}/${startDate}/${endDate}`, {
            method: "GET",
            headers: {
                Accept: "*/*",
                "Content-Type": "application/json",
                Authorization: "Bearer " + accessToken,
                "Access-Control-Allow-Origin": "*",
            }
        }).then((res) => res.json())
            .then(async (data) => {
                this.setState({
                    globalMinTemp: Number(TI_GALLERY_MIN_TEMP),
                    globalMaxTemp: Number(TI_GALLERY_MAX_TEMP),
                });
                let result2: any[] = []
                if (data[1].length > 0) {
                    this.setState({
                        currSAS: data[1][0].split('.png?')[1].split('&date')[0],

                    })
                    data[1]?.forEach((ele, idx) => {
                        let temp = ele.split("/thermal-image/")[1];
                        temp = temp.split(".png")[0];
                        temp = temp.split(".")[0];
                        let datetime2 = temp.substring(temp.indexOf('-') + 1)
                        if((this.state.recentImage && this.state.recentImage['original'] && this.state.recentImage['original'].includes("Z-")) || (this.state.recentImage && this.state.recentImage['original'] && this.state.recentImage['original'].includes("Z--")))
                        {
                            datetime2 = datetime2.replace("T"," ").substring(0,datetime2.length - 3)
                        }
                        else
                        {
                            datetime2 = datetime2.substring(0, datetime2.indexOf(':') + 3)
                        }
                        let imd_gallery_data = {
                            original: ele.split("&date")[0],
                            description: datetime2
                        }
                        result2.push(imd_gallery_data)
                    });
                    this.setState({
                        imageList: result2
                    })
                    await this.setState({
                        flag: true,
                        img_gallery: result2,
                        selectedCOilCenterTemp: []
                    })

                    this.onCoilUpdate()
                }
                else {
                    await this.setState({
                        flag: true,
                        img_gallery: result2,
                        imgGalleryLoader: false
                    });
                }
            },
                (error) => console.log("error", error)
            );
    }

    changeStartDateForGallery = (val) => {
        let newStartd: Date = new Date(val);
        let endDateForGallery = newStartd.setDate(newStartd.getDate() + 3);
        this.setState(
            {
                startDateForGallery : new Date(val),
                endDateForGallery : new Date(endDateForGallery)
        })
    }

    setStartDateTimePickerForGallery = (val) => {
        let newStartd: Date = new Date(val);
        let startDateForGallery = newStartd.setDate(newStartd.getDate() - 3);

        this.setState({startDateForGallery: new Date(startDateForGallery)})
    }

    getMinDateForGalleryStart = (inputDate) => {
        const date = new Date(inputDate);
        date.setDate(date.getDate() - 90)
        return date
    }
    render() {
        return (
            <div className="thermalimage_overview">

                {this.state.flag ?
                    <>
                        {this.props.accessibleFeatures.includes("ADVANCED_THERMAL_IMAGE") && 
                        <TmtInformationBlock cameraData={this.state.camera} />}
                        {/* {this.props.accessibleFeatures.includes("ADVANCED_THERMAL_IMAGE") &&  */}
                        <Paper elevation={3} className='controlPanelTI'>
                            <div className='cameraSelector'>
                                <div className='latestImgDescription'>
                                    {!this.props.accessibleFeatures.includes("ADVANCED_THERMAL_IMAGE") && 
                                    <FurnaceSelector fromTMT="fromTMT"  />}
                                    <div className='cameraSelectorTag'>
                                        <CameraSelector cameraData={this.state.camera}/>
                                    </div>
                                    <div className='datetpicker_parent'>
                                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                            <KeyboardDateTimePicker
                                                ampm={false}
                                                label="Select Datetime"
                                                value={this.state.latestImgDatetime}
                                                onChange={(val) => {
                                                    this.changeDatetime(val)
                                                }}
                                                onError={console.log}
                                                minDate={this.getMinDate(new Date())}
                                                maxDate={new Date()}
                                                format="yyyy-MM-dd HH:mm"
                                            />
                                        </MuiPickersUtilsProvider>
                                        <div className='btnNow'>
                                            <Button variant="outlined" startIcon={<Refresh />} onClick={() => this.refreshLatestImage()}>
                                                Now
                                            </Button>
                                        </div>

                                    </div>
                                    {!this.state.isEditPanel &&
                                        <>
                                            <div>
                                                <div className="edit_assignment">Edit color assignment</div>
                                                <Button variant="outlined" startIcon={<Edit />} onClick={() => this.editPanel()}>
                                                    Edit
                                                </Button>
                                            </div>
                                            {this.state.updatedMinTemp !== 0 && this.state.updatedMaxTemp !== 0 &&
                                                <div className='btnEdit'>
                                                    <Button variant="outlined" startIcon={<Refresh />} onClick={() => this.resetEditPanel()}>
                                                        Reset
                                                    </Button>
                                                </div>}
                                        </>
                                    }
                                    {this.state.isEditPanel &&
                                        <div className='editPanel'>
                                            <TextField className='minTempField editTextField' error={this.state.updatedMinTemp < Number(TI_MIN_UPDATED_TEMP) ? true : false} helperText={"Value should be more than "+ TI_MIN_UPDATED_TEMP} id="minTemp" label="Min Temp" value={this.state.updatedMinTemp} onChange={(e) => this.setState({ updatedMinTemp: Number(e.target.value) })} />
                                            <TextField className='maxTempField editTextField' id="maxTemp" error={(this.state.updatedMaxTemp < Number(this.state.updatedMinTemp + 10) || this.state.updatedMaxTemp > Number(TI_MAX_UPDATED_TEMP)) ? true : false} helperText={'Value should be between ' + Number(this.state.updatedMinTemp + 10) + ' & ' + Number(TI_MAX_UPDATED_TEMP)} label="Max Temp" value={this.state.updatedMaxTemp} onChange={(e) => this.setState({ updatedMaxTemp: Number(e.target.value) })} />
                                            <div className='editPanelBtn'>
                                                <Button variant="outlined" disabled={this.state.updatedMinTemp < Number(TI_MIN_UPDATED_TEMP) || this.state.updatedMaxTemp > Number(TI_MAX_UPDATED_TEMP) || (this.state.updatedMaxTemp < this.state.updatedMinTemp + 10)} startIcon={<Save />} onClick={() => this.updateTemp()}>
                                                    Update
                                                </Button>
                                                <Button variant="outlined" onClick={() => this.editPanel()}>
                                                    Cancel
                                                </Button>
                                            </div>
                                        </div>
                                    }
                                </div>
                            </div>
                        </Paper>
                        
                        <div className='parentLatestImg'>
                            {!this.state.LatestImgflag ?
                                <>
                                    <Paper elevation={3} className='paperLatestImg'>
                                        <p className='latestImgP'>Thermal Image Panel 
                                        {this.props.accessibleFeatures.includes("ADVANCED_THERMAL_IMAGE") && <MaterialUITooltip className='materialUITooltip' title="Please select any coil in below image to get temperature profile.">
                                            <IconButton>
                                                <Info />
                                            </IconButton>
                                        </MaterialUITooltip>}
                                        </p>

                                        <div id='latestImg' className='latestImg '>
                                            <div className='image-gallery-image-holder'>
                                                <img className={this.state.latestImageLoader ? "image-gallery-image opacity" : 'image-gallery-image'} alt="latest img" src={this.state.latestImg} loading="eager" onClick={(e) => this.callMouseDown(e)} />
                                            </div>
                                            {this.state.latestImageLoader ? <div className="loader-grid-parent latestImgLoader">
                                                <div className="loader-grid">
                                                    <Loader />
                                                    <p> Try Again !</p>
                                                </div>
                                            </div> : <></>}
                                            {this.state.latestImg !== "" &&
                                                <>
                                                    <div className='sidepanel sidepanel-latest'>
                                                        <DialogContent>
                                                        {this.props.accessibleFeatures.includes("ADVANCED_THERMAL_IMAGE") && <CoilSelector />}
                                                            <DialogContentText color='textPrimary'>
                                                                <b>Date: </b>{this.pad((this.state.latestImgDatetime?.getMonth() ?? 0) + 1, 2)}/{this.pad(this.state.latestImgDatetime?.getDate(), 2)}/{this.state.latestImgDatetime?.getFullYear()}
                                                            </DialogContentText>
                                                            <DialogContentText color='textPrimary'>
                                                                <b>Time: </b>{this.pad(this.state.latestImgDatetime?.getHours(), 2)}:{this.pad(this.state.latestImgDatetime?.getMinutes(), 2)}
                                                            </DialogContentText>
                                                            {this.state.latestImageCoilName !== '' && <DialogContentText color='textPrimary'>
                                                                <span className='minTempMark' style={{ marginTop: '5px' }} ></span>
                                                                <b style={{ marginLeft: '15px' }}>Lowest Temperature: </b>{ParseTemperatureValue(this.state.latestMinTemp)} {getTemperatureUnit()}
                                                            </DialogContentText>}
                                                            {this.state.latestImageCoilName !== '' &&
                                                                <DialogContentText color='textPrimary'>
                                                                    <span className='maxTempMark' style={{ marginTop: '5px' }} ></span>
                                                                    <b style={{ marginLeft: '15px' }}>Hottest Temperature: </b>{ParseTemperatureValue(this.state.latestMaxTemp)} {getTemperatureUnit()}
                                                                </DialogContentText>}

                                                            <div className='color_box'>
                                                            </div>
                                                            <div className='color_box_data'>
                                                                <p>{this.state.maxProjectTemperature}</p>
                                                                <p>{this.state.minProjectTemperature}</p>
                                                            </div>
                                                            {this.state.latestImageCoilName !== '' && <hr className='seperatorHr'></hr>}
                                                            {this.state.latestImageCoilName !== '' && <div className='legends'>

                                                                <span className='inletCoilHighlight'>| : |</span><b >Inlet</b>
                                                                <span className='outletCoilHighlight'>| : |</span><b>Outlet</b>
                                                            </div>}
                                                        </DialogContent>
                                                    </div>

                                                    {this.state.latestMaxTempXCor && <span className='maxTempMarkLatest' style={{ marginLeft: this.state.latestMaxTempXCor, marginTop: this.state.latestMaxTempYCor }}></span>}
                                                    {this.state.latestMinTempXCor && <span className='minTempMarkLatest' style={{ marginLeft: this.state.latestMinTempXCor, marginTop: this.state.latestMinTempYCor }}></span>}
                                                </>
                                            }                                           
                                            <div className='btnNavigations'>
                                                <div className='btnNavigation'>
                                                    <Button disabled={this.state.navButtonEnable} variant="outlined" color="primary" startIcon={<FastRewind fontSize='large' />} onClick={() => this.fastRewind()}>
                                                    </Button>
                                                </div>
                                                <div className='btnNavigation'>
                                                    <Button disabled={this.state.navButtonEnable} variant="outlined" color="primary" startIcon={<NavigateBefore fontSize='large' />} onClick={() => this.navPrev()}>
                                                    </Button>
                                                </div>
                                                <div className='btnNavigation'>
                                                    <Button disabled={this.state.navButtonEnable} variant="outlined" color="primary" startIcon={<SkipNext fontSize='large' />} onClick={() => this.refreshLatestImage()}>
                                                    </Button>
                                                </div>
                                                <div className='btnNavigation'>
                                                    <Button disabled={this.state.navButtonEnable} variant="outlined" color="primary" startIcon={<NavigateNext fontSize='large' />} onClick={() => this.navNext()}>
                                                    </Button>
                                                </div>
                                                <div className='btnNavigation'>
                                                    <Button disabled={this.state.navButtonEnable} variant="outlined" color="primary" startIcon={<FastForward fontSize='large' />} onClick={() => this.fastForward()}>
                                                    </Button>
                                                </div></div>
                                        </div>
                                    </Paper>

                                    {this.state.selectedCOilCenterTemp.length > 0 &&
                                        <Paper elevation={3} className='paperLatestImgGraph'>
                                            <p className='latestImgP'>Coil TMT Panel</p>
                                            <div className='chartParent'>
                                                <ResponsiveContainer width="100%" height="100%">
                                                    <LineChart
                                                        layout="vertical"
                                                        width={500}
                                                        height={300}
                                                        data={this.state.selectedCOilCenterTemp}
                                                        margin={{
                                                            top: 20,
                                                            right: 30,
                                                            left: 20,
                                                            bottom: 5,
                                                        }}
                                                    >
                                                        <CartesianGrid stroke="#eee" strokeDasharray="5 5" />
                                                        <XAxis type="number" tickCount={25} domain={[this.state.minCoilTmtPanel, this.state.maxCoilTmtPanel]} />
                                                        <YAxis label={{ value: "Distance from furnace top (m)", angle: -90, position: "left", offset: 0 }} dataKey="CoilLength" type="category" />
                                                        <Tooltip />
                                                        <Legend />
                                                        <Line type="monotone" dataKey={"OutletTemperature("+getTemperatureUnit()+")"} stroke="#8884d8" activeDot={{ r: 1 }} />
                                                        <Line type="monotone" dataKey={"InletATemperature("+getTemperatureUnit()+")"} stroke="#82ca9d" activeDot={{ r: 1 }} />
                                                        <Line type="monotone" dataKey={"InletBTemperature("+getTemperatureUnit()+")"} stroke="#ffc658" activeDot={{ r: 1 }} />
                                                        <Line type="monotone" dataKey={this.state.coilGroups[0]} stroke="#4BC0C0" activeDot={{ r: 1 }} />
                                                        {this.state.coilGroups.filter(cg => cg !== this.state.coilGroups[0]).map(x =>   <><Line type="monotone" dataKey={x} stroke="#000000" activeDot={{ r: 1 }} /></>)}
                                                    </LineChart>
                                                </ResponsiveContainer>
                                            </div>
                                        </Paper>
                                    }

                                </>
                                : <>
                                    {this.state.showWarningMessage ?
                                        <><Paper className='noImageCard'>
                                            <p> No Image Found For Selected Datetime.</p>
                                        </Paper>
                                            <br></br>
                                        </>
                                        : <Paper elevation={3} className='paperLatestImgGraph'><div className="loader-grid-parent latestImageLoading">
                                            <div className="loader-grid">
                                                <Loader />
                                                <p> Loading...</p>
                                            </div>
                                        </div>
                                        </Paper>
                                    }
                                </>

                            }
                        </div>
                        <Paper elevation={3} className='paperGallery'>
                            {/* <p className='latestImgP'>Thermal Video Panel</p> */}
                            <DialogContent className='temperatureChart'>
                            <span className="galleryTitle">Thermal Video Panel</span>
                                {/* <DialogContentText color='textPrimary'>
                                    <b>Date: </b>{this.pad((this.state.currDatetime?.getMonth() ?? 0) + 1, 2)}/{this.pad(this.state.currDatetime?.getDate(), 2)}/{this.state.currDatetime?.getFullYear()}
                                </DialogContentText>
                                <DialogContentText color='textPrimary'>
                                    <b>Time: </b>{this.pad(this.state.currDatetime?.getHours(), 2)}:{this.pad(this.state.currDatetime?.getMinutes(), 2)}
                                </DialogContentText> */}
                                {/* <DialogContentText color='textPrimary'>
                                    <span className='minTempMark' style={{ marginTop: '5px' }} ></span>
                                    <b style={{ marginLeft: '15px' }}>Lowest Temperature: </b>{this.state.currMinTemp} °C
                                </DialogContentText>
                                <DialogContentText color='textPrimary'>
                                    <span className='maxTempMark' style={{ marginTop: '5px' }} ></span>
                                    <b style={{ marginLeft: '15px' }}>Hottest Temperature: </b>{this.state.currMaxTemp} °C
                                </DialogContentText> */}
                                <div className='color_box_gallery'>
                                </div>
                                <div className='color_box_data'>
                                    {/* <p>{this.state.currMaxTemp === 0 ? this.state.maxProjectTemperature : Math.ceil(this.state.currMaxTemp ?? 0)}</p>
                                    <p>{this.state.currMinTemp === 0 ? this.state.minProjectTemperature : Math.floor(this.state.currMinTemp ?? 0)}</p> */}
                                    <p className='galleryMinTempValue'>{this.state.globalMinTemp}</p>
                                    <p className='galleryMaxTempValue'>{this.state.globalMaxTemp}</p>
                                </div>
                                <div className='galleryDatePickers'>
                                    <div className='galleryStartDatePicker'>
                                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                        <KeyboardDateTimePicker
                                            ampm={false}
                                            label="Start Date"
                                            value={this.state.startDateForGallery}
                                            onChange={(val) => {
                                                this.changeStartDateForGallery(val)
                                            }}
                                            onError={console.log}
                                            minDate={this.getMinDateForGalleryStart(this.state.maxDateForGalleryStart)}
                                            maxDate={new Date(this.state.maxDateForGalleryStart)}
                                            format="yyyy-MM-dd HH:mm"
                                        />
                                    </MuiPickersUtilsProvider>
                                    </div>
                                    <div className='galleryEndDatePicker'>
                                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                        <KeyboardDateTimePicker
                                            ampm={false}
                                            label="End Date"
                                            value={this.state.endDateForGallery}
                                            onChange={(val) => {
                                                this.changeDatetime(val)
                                            }}
                                            onError={console.log}
                                            format="yyyy-MM-dd HH:mm"
                                            disabled={true}
                                        />
                                    </MuiPickersUtilsProvider>
                                    </div>
                                </div>
                            </DialogContent>
                            {this.state.img_gallery.length > 1 ?
                                <div className='imgGallery'>
                                    <ImageGallery
                                        className={'gallery-control'}
                                        items={this.state.img_gallery}
                                        showThumbnails slideDuration={0}
                                        slideInterval={500}
                                        showNav={true}
                                        ref={this.state.refObj}
                                        showBullets={true}
                                        showFullscreenButton={false}
                                        onClick={(e) => this.onClick(e)}
                                        // onPause={this.onPause}
                                        // onPlay={this.onPlay}
                                        autoPlay={true}
                                    />
                                    {this.state.isPlaying === false
                                        ? (
                                            <>
                                                {/* <span className='maxTempMark' style={{ marginLeft: this.state.currImgMaxTempXCor, marginTop: this.state.currImgMaxTempYCor }}></span>
                                                <span className='minTempMark' style={{ marginLeft: this.state.currImgMinTempXCor, marginTop: this.state.currImgMinTempYCor }}></span> */}

                                                <div className='sidepanel sidePanelGallery'>
                                                    <DialogContent>
                                                
                                                        
                                                        {/* <DialogContentText color='textPrimary'>
                                                            <b>Date: </b>{this.pad((this.state.currDatetime?.getMonth() ?? 0) + 1, 2)}/{this.pad(this.state.currDatetime?.getDate(), 2)}/{this.state.currDatetime?.getFullYear()}
                                                        </DialogContentText>
                                                        <DialogContentText color='textPrimary'>
                                                            <b>Time: </b>{this.pad(this.state.currDatetime?.getHours(), 2)}:{this.pad(this.state.currDatetime?.getMinutes(), 2)}
                                                        </DialogContentText> */}
                                                        {/* <DialogContentText color='textPrimary'>
                                                            <span className='minTempMark' style={{ marginTop: '5px' }} ></span>
                                                            <b style={{ marginLeft: '15px' }}>Lowest Temperature: </b>{this.state.currMinTemp} °C
                                                        </DialogContentText>
                                                        <DialogContentText color='textPrimary'>
                                                            <span className='maxTempMark' style={{ marginTop: '5px' }} ></span>
                                                            <b style={{ marginLeft: '15px' }}>Hottest Temperature: </b>{this.state.currMaxTemp} °C
                                                        </DialogContentText> */}
                                                        {/* <div className='color_box_gallery'>
                                                        </div>
                                                        <div className='color_box_data'> */}
                                                            {/* <p>{this.state.currMaxTemp === 0 ? this.state.maxProjectTemperature : Math.ceil(this.state.currMaxTemp ?? 0)}</p>
                                                            <p>{this.state.currMinTemp === 0 ? this.state.minProjectTemperature : Math.floor(this.state.currMinTemp ?? 0)}</p> */}
                                                            {/* <p>{this.state.globalMaxTemp}</p>
                                                            <p>{this.state.globalMinTemp}</p>
                                                        </div> */}
                                                    </DialogContent>
                                                
                                                </div>
                                            </>)
                                        : null}

                                </div>
                                :
                                (this.state.imgGalleryLoader
                                    ?
                                    <div className="loader-grid-parent loader-grid-gallery">
                                        <div className="loader-grid">
                                            <Loader />
                                            <p> Loading...</p>
                                        </div>
                                    </div>
                                    :
                                    <span>Video Not Available: Dataset not found or cannot load from source! Please, contact with product owner! </span>
                                )
                            }
                        </Paper>
                    </>
                    : <>
                        <div className="loader-grid-parent">
                            <div className="loader-grid">
                                <Loader />
                                <p> Loading...</p>
                            </div>
                        </div>
                    </>
                }
            </div >
        )
    }
}

export default withAuth(connector(ThermalImageOverview));