import React, { Component } from "react";
import { connect, ConnectedProps } from "react-redux";
import { RootState } from "../../../reducers/rootReducer";
import { getAllRolesData, getAllUserRolesFromDB } from "../../../services/userRolesServices";
import { loadAllUsers, loadAllRoles } from "../../../slices/userRolesSlice";
import AddEditRoles from "./addEditRoles";
import DeleteRoles from "./deleteRoles";
import { IUserRolesResponse } from "../../../interfaces/IadminPanel";
import "../../../assets/styles/_rolesTable.scss"
import { AuthContextProps, withAuth } from "react-oidc-context";
import { getToken } from "../../../utilities/helperFunctions";

interface IRolesTableProps {
}

interface OIDCAuth {
    auth?: AuthContextProps
}

const mapStateToProps = (state: RootState) => {
    return {
        allUsers: state.adminPanel.adminPanelUsers,
        currentUserRoleIndex: state.adminPanel.currentUserRoleState.roleIndex
    };
};

const mapDispatchToProps = (dispatch: any) => {
    return {
        loadAllAdminUsers: (allUsers: any) => dispatch(loadAllUsers(allUsers)),
        loadUserRoles: (allRoles: IUserRolesResponse) => dispatch(loadAllRoles(allRoles))
    };
};
const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

type IRolesTableReduxProps = PropsFromRedux & IRolesTableProps & OIDCAuth;


type IRolesTableState = {
    showLoading: boolean;
    roles: any[]
    disableIcon: boolean;
}

class RolesTable extends Component<IRolesTableReduxProps, IRolesTableState> {

    constructor(props) {
        super(props);

        this.state = {
            showLoading: false,
            roles: [],
            disableIcon: false
        }
    }

    async componentDidMount() {
        await this.getAllRoles()
        await this.getAllUserRolesFromDB()
    }

    async getAllRoles() {
        this.setState({
            showLoading: true
        })
        const accessToken = getToken(this.props.auth);
        let allRolesData = await getAllRolesData(accessToken);
        if (allRolesData.status === true) {
            await this.props.loadUserRoles(allRolesData)
            this.setState({
                showLoading: false,
                roles: allRolesData.data
            })
        } else {
            this.setState({
                showLoading: false
            })
        }

    }

    async getAllUserRolesFromDB() {
        this.setState({
            showLoading: true
        })
        const accessToken = getToken(this.props.auth);
        let allUsers = await getAllUserRolesFromDB(accessToken);

        if (allUsers.status === true) {
            await this.props.loadAllAdminUsers(allUsers.data)
            this.setState({
                showLoading: false,
            })
        } else {
            this.setState({
                showLoading: false
            })
        }
    }

    getRoleName = (roleId: number) => {
        let userRole = this.state.roles.find(x => x.id === roleId);
        let str = !!userRole && !!userRole.display_name ? userRole.display_name : "";
        if (!!str) {
            let str2 = str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
            return str2
        } else {
            return ""
        }

    }

    

    render() {
        return (
            <>
                <div className="container">
                    <div className="feedstock">
                        <div className="admin-panel-table-wrapper">
                            <table className="table" id="admin-panel-table">
                                <thead >
                                    <tr>
                                        {/* <th>No</th> */}
                                        <th style={{ textAlign: 'left' }}>Name</th>
                                        <th style={{ textAlign: 'left' }}>Email</th>
                                        <th style={{ textAlign: 'left' }}>Role</th>
                                        <th style={{ textAlign: 'left' }}>Updated By</th>
                                        <th style={{ textAlign: 'left' }}>Updated At</th>
                                        <th style={{ textAlign: 'left' }}>Actions</th>
                                    </tr>
                                </thead>

                                <tbody style={{ wordWrap: "break-word"}}>
                                    {this.props.allUsers.length === 0 && (
                                        <tr>
                                            <td colSpan={4}><b>No data available</b></td>
                                        </tr>
                                    )}
                                    {!!this.props.allUsers &&
                                        this.props.allUsers.length > 0 &&
                                        this.props.allUsers.map((user, index) => {
                                            let userUpdatedAt: string  = "-";
                                            if(user?.updated_at) {
                                                const userUpdatedAtDate = new Date(user.updated_at);
                                                userUpdatedAt = new Date(Date.parse(userUpdatedAtDate.toString()) - userUpdatedAtDate.getTimezoneOffset() * 60000).toLocaleString();  //UTC tp Local time
                                            }
                                    
                                            return (
                                                <tr key={'adminPanelUser_' + user?.id}>
                                                    {/* <td>{index + 1}</td> */}
                                                    <td style={{ textAlign: 'left' }}>{user?.name}</td>
                                                    <td style={{ textAlign: 'left' }}>{user?.email}</td>
                                                    <td style={{ textAlign: 'left' }}>{this.getRoleName(user?.role)}</td>
                                                    <td style={{ textAlign: 'left' }}>{user?.updated_by || "-"}</td>
                                                    <td style={{ textAlign: 'left' }}>{userUpdatedAt}</td>
                                                    <td>
                                                        <div style={{ display: "flex" }}>
                                                            <div>
                                                                <AddEditRoles popupName={'Edit'} id={user?.id} disabled={this.props.currentUserRoleIndex > user?.role} />
                                                            </div>
                                                            <div>
                                                                <DeleteRoles id={user?.id} disabled={this.props.currentUserRoleIndex > user?.role} />
                                                            </div>
                                                        </div>
                                                    </td>
                                                </tr>
                                            )
                                        })
                                    }
                                    {/* <tr>
                                        <td>1</td>
                                        <td>Test User</td>
                                        <td>test1@mail.com</td>
                                        <td>User</td>
                                        <td>
                                            <div style={{
                                                textAlign: "center", marginLeft: "140px", marginTop: "5px"
                                            }}>
                                                <AddEditRoles popupName={'Edit'} id={0} />
                                            </div>
                                            <div style={{
                                                textAlign: "center", marginLeft: "140px", marginTop: "5px"
                                            }}>
                                                <DeleteRoles id={0} />
                                            </div>
                                        </td>
                                    </tr> */}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}

export default withAuth(connector(RolesTable));