import React, { Component } from "react";
import { connect, ConnectedProps } from "react-redux";
import {
  Typography,
  Card,
  CardHeader,
  IconButton,
  Grid,
  Paper,
} from "@material-ui/core";
import { REACT_APP_APIM_URL, TI_API_CALL_INTERVAL, TI_GALLERY_MAX_TEMP, TI_GALLERY_MIN_TEMP } from "../../utilities/GlobalConstants";
import store from "../..";
import "../../assets/styles/_multi-camera.scss"
import FurnaceSelector from "../common/FurnaceSelector";
import { RootState } from "../../reducers/rootReducer";
import { getFurnaceCameras, getCameraIdByCameraName, getFurnaceByCameraId } from "../../configuration";
import Loader from "react-spinners/ClipLoader";
import { setFurnaceId } from "../../slices/furnaceSlice";
import { setCurrentCamera, setCurrentCameraId, setSelectedTimestamp } from '../../slices/cameraSlice'
import { setCurrentFurnaceId } from "../../slices/scenarioSimulatorSlice";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { AuthContextProps, withAuth } from "react-oidc-context";
import { getFormattedDateTime, getToken } from "../../utilities/helperFunctions";
let pin_path = require("../../assets/icons/pin.png");

export const MULTI_CAMERA_DASHBOARD_ID = "_MULTI_CAMERA";

interface IMultiCameraProps extends RouteComponentProps{ }

interface OIDCAuth {
  auth?: AuthContextProps
}

const mapStateToProps = (state: RootState) => {
  return {
    furnaces: state.furnace,
    currentPlantId: state.scenarioSimulator.currentPlantId,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    setFurnaceId: (newFurnaceId: string) => dispatch(setFurnaceId(newFurnaceId)),
    setCurrentCamera: (name: string) => dispatch(setCurrentCamera(name)),
    setCurrentCameraId: (id: string) => dispatch(setCurrentCameraId(id)),
    setCurrentFurnaceId: (newFurnaceId: string) => dispatch(setCurrentFurnaceId(newFurnaceId)),
    setSelectedTimestamp: (timeStamp: string) => dispatch(setSelectedTimestamp(timeStamp))
  }
}

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

type IMulticameraReduxProps = PropsFromRedux & IMultiCameraProps & OIDCAuth;

type tmtNotificationData = {
  furnace_id: string,
  camera_id: number,
  coil_id: string,
  coilgroup_id: string
};

type IMulticameraState = {
  images: [],
  selectedImage: any,
  furnaces: any,
  multiCameraLoader: boolean,
  globalMinTemp: number,
  globalMaxTemp: number,
  tmtNotificationData: tmtNotificationData[],
  furnaceId: number
}



class MultiCamera extends Component<IMulticameraReduxProps, IMulticameraState>{
  interval;
  constructor(props) {
    super(props);

    this.state = {
      images: [],
      selectedImage: [],
      furnaces: this.props.furnaces,
      multiCameraLoader: true,
      globalMinTemp: 0,
      globalMaxTemp: 0,
      tmtNotificationData: [],
      furnaceId: 0
    }
  }

  setItem = (image: { src: string; name: string }) => {
    let arr: { src: string; name: string }[] = [...this.state.selectedImage];
    if (arr.length === 2) {
      arr[0] = arr[1];
      arr[1] = image;
      return this.setState({ selectedImage: arr });
    }
    arr.push(image);
    this.setState({ selectedImage: arr })
  };

  componentDidMount() {
    const furnaceId = this.props.furnaces.current_furnace_id;
    this.setState({ furnaceId: parseInt(furnaceId) })
    this.getCameraByFurnaceId(furnaceId)
    this.interval = setInterval(() => this.getCameraByFurnaceId(this.props.furnaces.current_furnace_id), Number(TI_API_CALL_INTERVAL))
    this.setState({
      globalMinTemp: Number(TI_GALLERY_MIN_TEMP),
      globalMaxTemp: Number(TI_GALLERY_MAX_TEMP),
    });
  };

  componentWillUnmount(): void {
    clearInterval(this.interval)
  }

  componentDidUpdate(prevProps,prevState) {
    if ((prevProps.furnaces.current_furnace_id !== this.props.furnaces.current_furnace_id) || (prevState.furnaceId !== this.state.furnaceId)) {
      this.getCameraByFurnaceId(this.props.furnaces.current_furnace_id)
    }
  }

  getCameraByFurnaceId = (furnaceId) => {
    this.setState({ multiCameraLoader: true });
    const accessToken = getToken(this.props.auth);
    fetch(`${REACT_APP_APIM_URL}/thermalimage/getCameraByFurnaceId/${furnaceId}`, {
      method: "GET",
      headers: {
        Accept: "*/*",
        "Content-Type": "application/json",
        Authorization: "Bearer " + accessToken,
        "Access-Control-Allow-Origin": "*"
      },
    })
      .then((res) => res.json())
      .then(
        (resp) => {
          this.setState({
            images: resp,
            multiCameraLoader: false,
            selectedImage:[],
            furnaceId: furnaceId
          })
        },
        (error) => {
          this.setState({ multiCameraLoader: false })
          console.log("error", error)
        }
      )
  }

  getDisplayName = (name) => {
    let furnaceCameras = getFurnaceCameras(this.state.furnaceId);
    let filterCamera = furnaceCameras.filter(function (item) {
      return item.name === name;
    });
    return filterCamera[0].display_name
  }

  rediectToThermalImage = (cameraName, selectedTimeStamp) => {
    let cameraId = getCameraIdByCameraName(cameraName);
    let furnaceId = getFurnaceByCameraId(cameraId);
    this.props.setFurnaceId(furnaceId);
    this.props.setCurrentFurnaceId(furnaceId);
    this.props.setCurrentCameraId(cameraId);
    this.props.setSelectedTimestamp(selectedTimeStamp);
    this.props.setCurrentCamera(cameraName);
    this.props.history.push('/thermalimage');
  }

  getRunlength = () => {
    let runLengthArr  = this.props.furnaces.furnace_metrics.filter((furnace) => furnace.furnace_id == this.props.furnaces.current_furnace_id)
    let runLength: number | string = runLengthArr.length > 0 ? runLengthArr[0]['Actual Runlength'] : 'N/A';
    return runLength
  }

  getFurnaceMode = () => {
    let selectedFurnace  = this.props.furnaces.furnace_metrics.filter((furnace) => furnace.furnace_id == this.props.furnaces.current_furnace_id)
    return selectedFurnace[0]['State']
  }

  render() {
    
    return (
      <React.Fragment>
        <div>
          <Paper elevation={3} className='controlPanelTI multi-camera-panel'>
            <div className="control-header element tmtgen tmtgen-holder">
              <div className="container-typography tmtgen">
                <Grid item direction="row" justifyContent="flex-start" xs={12} lg={12} xl={12} className="gen_display">
                  <FurnaceSelector fromTMT="fromTMT" />
                  <Grid className="multi-camera-right-panel-holder">
                    <span className="cell_spacing tmt_furnace_header"><b>Run length(d) :</b> {this.getRunlength()} </span>
                    <span className="cell_spacing tmt_furnace_header"><b>Furnace Mode :</b> {this.getFurnaceMode() || (this.props.furnaces.furnace_op_mode)}</span>
                  </Grid>
                  <Grid className="multi-camera-right-panel-holder multi-camera-right-panel">
                    <Grid className="">
                      <span className="cell_spacing"><b>Tmt Status Normal</b><span className="tmt_green_bg"></span></span>
                      <Grid className='color_box_gallery multi-camera-color-box'></Grid>
                    </Grid>
                    <Grid className="devaition-legend-holder">
                      <span className="cell_spacing"><b>Tmt Status Deviation</b><span className="tmt_deviation_bg"></span></span>
                      <span className='multi-camera-color-box-galleryMinTempValue'>{this.state.globalMinTemp}</span>
                      <span className='multi-camera-color-box-galleryMaxTempValue'>{this.state.globalMaxTemp}</span>
                    </Grid>
                  </Grid>
                </Grid>
              </div>
            </div>
          </Paper>
          {this.state.multiCameraLoader
            ?
            <div className="loader-grid-parent">
              < div className="loader-grid">
                <Loader />
                <p> Loading...</p>
              </div>
            </div>
            :
            <>
              
                <Grid
                  container
                 
                >

                  {this.state.selectedImage.length > 0 && (
                    <Grid item lg={5}>
                      <Grid container lg={6}>
                        {this.state.selectedImage.map((item, index) => (
                          <Grid item lg={12}>
                            <Card
                              className="img-card"
                              style={{
                                width: this.state.selectedImage.length === 1 ? "640px" : "640px",
                                height: this.state.selectedImage.length === 1 ? "480px" : "480px",
                                marginTop: index === 1 ? "20px" : "",
                              }}
                            >
                              <CardHeader
                                className="image-card-header"
                                title={<Typography className="image-card-header-typography"><p>{this.getDisplayName(item.name)}</p><p className="image-date-time">{new Date(Number(item.timeStamp)).toLocaleString()}</p><p><span className={item.severity == 1? "tmt_deviation_bg" : "tmt_green_bg"}></span></p></Typography>}
                                action={
                                  <IconButton className="pin-push-icon"
                                    onClick={() => {
                                      const arr = this.state.selectedImage.filter(
                                        (value) => value !== item
                                      );
                                      this.setState({ selectedImage: arr })
                                    }}
                                  >
                                    <img src={pin_path} className="pin-push" />
                                  </IconButton>
                                }
                              />
                              <img
                                src={item.src}
                                alt={item.src}
                                onClick={() => this.rediectToThermalImage(item.name, item.timeStamp)}
                                width="100%"
                                height="100%"
                              />
                            </Card>
                          </Grid>
                        ))}
                      </Grid>
                    </Grid>
                  )}
                  <Grid item lg={this.state.selectedImage.length > 0 ? 7 : 12} xs={6}>
                    <Grid container spacing={2} wrap="wrap">
                      {this.state.images.map(
                        (item: any, index) =>
                          !this.state.selectedImage.some(
                            (selectedImage) => selectedImage.src === item.src
                          ) && (
                            <Grid
                              item
                              lg={
                                this.state.selectedImage.length === 1
                                  ? 4
                                  : this.state.selectedImage.length === 0
                                    ? 3
                                    : 4
                              }
                              md={6}
                            >
                              <Card
                                variant="outlined"
                                className="small-image-card-holder"

                              >
                                <CardHeader
                                  className="image-card-header"
                                  title={
                                    <Typography className="image-card-header-typography">
                                      <p>{this.getDisplayName(item.name)}</p><p className="image-date-time">{getFormattedDateTime(new Date(Number(item.timeStamp)))}</p><p><span className={item.severity == 1? "tmt_deviation_bg" : "tmt_green_bg"}></span></p>
                                    </Typography>
                                  }
                                  action={
                                    <IconButton className="pin-push-icon" onClick={() => this.setItem(item)}>
                                      <img src={pin_path} className="pin-push" />
                                    </IconButton>
                                  }
                                />
                                <img className="multi-camera-image" src={item.src} alt={this.getDisplayName(item.name)} onClick={() => this.rediectToThermalImage(item.name,item.timeStamp)} />
                              </Card>
                            </Grid>
                          )
                      )}
                    </Grid>
                  </Grid>
                </Grid>
              
            </>
          }
        </div>
      </React.Fragment >
    );
  }
}

export default withAuth(connector(withRouter(MultiCamera)));
